@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Rubik', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  min-width: 240px;
  width: 100%;
  /* min-height: 100%;
  height: fit-content; */
}

body * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
